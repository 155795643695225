/* Chat.css */
.chat-container {
  position: fixed;
  bottom: 20px;
  right: 20px;
  width: 300px;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  max-height: 400px;
  overflow-y: auto;
  z-index: 1000;
}

.chat-header {
  background-color: #00938b;
  color: white;
  padding: 10px;
  text-align: center;
  border-radius: 8px 8px 0 0;
}

.chat-messages {
  padding: 10px;
  flex-grow: 1;
  overflow-y: auto;
  max-height: 300px;
}

.chat-message {
  background-color: #f1f1f1;
  padding: 8px;
  margin: 5px 0;
  border-radius: 5px;
}

.chat-input-container {
  display: flex;
  padding: 10px;
  background-color: #f9f9f9;
  border-radius: 0 0 8px 8px;
}

.chat-input-container input {
  flex-grow: 1;
  padding: 8px;
  border-radius: 5px;
  border: 1px solid #ddd;
}

.chat-input-container button {
  background-color: #00938b;
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.chat-input-container button:hover {
  background-color: #19c3d1;
}


.chat-input-container{
  display: 'flex';
  flex-direction: column;
  margin-top: 10px;

}


.message-buttons button {
  margin: 5px 0;
  padding: 8px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  text-align: left;
}


.message-buttons button:hover {
  background-color: #45a049;
}
