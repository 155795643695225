/* Change the width and color of the scrollbar */
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
  background-color: #f5f5f5;
}

/* Change the appearance of the scrollbar thumb */
::-webkit-scrollbar-thumb {
  background-color: #0094b6;
  border-radius: 5px;
}
